import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "login-card" }
const _hoisted_3 = { class: "login-main" }
const _hoisted_4 = {
  key: 0,
  class: "toaster-error"
}
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_9 = { class: "form-group mb-0" }
const _hoisted_10 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_11 = { class: "checkbox p-0" }
const _hoisted_12 = { class: "text-center mt-3" }

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { createToaster } from '@meforma/vue-toaster';
import { login } from '@/api/auth';


export default /*@__PURE__*/_defineComponent({
  __name: 'loginPage',
  setup(__props) {

const email = ref('');
const password = ref('');
const rememberMe = ref(false);
const formSubmitted = ref(false);
const emailError = ref(false);
const passwordError = ref(false);
const emailErrorMessage = ref('');
const passwordErrorMessage = ref('');
const serverError = ref('');

const toaster = createToaster({});
const router = useRouter();

function validateInputs() {
  formSubmitted.value = true;
  emailError.value = false;
  passwordError.value = false;
  emailErrorMessage.value = '';
  passwordErrorMessage.value = '';

  if (!email.value || !validateEmail(email.value)) {
    emailError.value = true;
    emailErrorMessage.value = 'Please enter a valid email address.';
  }
  if (!validatePassword(password.value)) {
    passwordError.value = true;
    passwordErrorMessage.value = 'Password must be at least 6 characters long.';
  }
}

function validateEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function validatePassword(password: string): boolean {
  return password.length >= 6;
}

async function onSubmit() {
  validateInputs();
  if (!emailError.value && !passwordError.value) {
    try {
      const response = await login(email.value, password.value, rememberMe.value);
      localStorage.setItem('token', response.token);
      router.push('/overview');
    } catch (error: any) {
      if (error.response && error.response.status >= 400 && error.response.status < 500) {
        serverError.value = error.response.data.message || 'Something went wrong. Please try again later.';
      } else {
        serverError.value = 'Something went wrong. Please try again later.';
      }
    }
  }
}

// function success() {
//   toaster.show('Verification link has been sent to your email', {
//     theme: 'outline',
//     position: 'top-right',
//     type: 'success',
//     duration: 5000,
//   });
// }

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          class: "theme-form",
          onSubmit: _withModifiers(onSubmit, ["prevent"])
        }, [
          _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-center" }, "Sign in to account", -1)),
          _cache[9] || (_cache[9] = _createElementVNode("p", {
            class: "text-center",
            style: {"margin-bottom":"15px"}
          }, "Enter your email & password to login", -1)),
          (serverError.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(serverError.value), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "col-form-label" }, "Email Address", -1)),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["form-control", formSubmitted.value ? (emailError.value ? 'is-invalid' : 'is-valid') : '']),
              type: "email",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
              placeholder: "Test@gmail.com"
            }, null, 2), [
              [_vModelText, email.value]
            ]),
            (formSubmitted.value && emailError.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(emailErrorMessage.value), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "col-form-label" }, "Password", -1)),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["form-control", formSubmitted.value ? (passwordError.value ? 'is-invalid' : 'is-valid') : '']),
              type: "password",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
              placeholder: "*********"
            }, null, 2), [
              [_vModelText, password.value]
            ]),
            (formSubmitted.value && passwordError.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(passwordErrorMessage.value), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _withDirectives(_createElementVNode("input", {
                  id: "checkbox1",
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((rememberMe).value = $event))
                }, null, 512), [
                  [_vModelCheckbox, rememberMe.value]
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("label", {
                  class: "text-muted",
                  for: "checkbox1"
                }, "Remember password", -1))
              ]),
              _createVNode(_component_router_link, { to: "/authentication/forget-password" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Forgot password?")
                ])),
                _: 1
              })
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-end mt-3" }, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block w-100",
                type: "submit"
              }, "Sign in")
            ], -1))
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("p", { class: "mt-4 mb-0 text-center" }, null, -1))
        ], 32)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_router_link, {
        class: "ms-2",
        to: "/privacyPolicy"
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createTextVNode("Privacy Policy")
        ])),
        _: 1
      }),
      _cache[13] || (_cache[13] = _createElementVNode("span", { class: "mx-2" }, "|", -1)),
      _createVNode(_component_router_link, {
        class: "ms-2",
        to: "/termsAndConditions"
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createTextVNode("Terms and Conditions")
        ])),
        _: 1
      })
    ])
  ]))
}
}

})