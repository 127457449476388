import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import BodyView from "@/layout/BodyView.vue"
import BodyView2 from "@/layout/BodyView2.vue"
import auth from "@/auth/authView.vue"
import login from "@/auth/loginPage.vue"


import indexDefault from "@/pages/dashbords/indexDefault.vue"
import indexEcommerce from "@/pages/dashbords/indexEcommerce.vue"
import agencyView from "@/components/agencyDashboard/agencyView.vue";

import emailIndex from "@/components/emailTemp/emailIndex.vue";






import indexProduct from "@/pages/ecommerce/indexProduct.vue"

import productPage from "@/pages/ecommerce/productPage.vue"

import paymentDetail from "@/pages/ecommerce/paymentDetail.vue"
import indexInvoice from "@/pages/ecommerce/indexInvoice.vue"
import indexCheckout from "@/pages/ecommerce/indexCheckout.vue"
import indexPricing from "@/pages/ecommerce/indexPricing.vue"
import indexWishlist from "@/pages/ecommerce/indexWishlist.vue"
import indexOrder from "@/pages/ecommerce/indexOrder.vue"
import indexProductlist from "@/pages/ecommerce/indexProductlist.vue"



import indexChat from "@/pages/chat/indexChat.vue"
import indexVideo from "@/pages/chat/indexVideo.vue"

import indexProfile from "@/pages/user/indexProfile.vue"
import indexEdit from "@/pages/user/indexEdit.vue"


import indexInputs from "@/pages/forms/formcontrols/indexInputs.vue"
import indexGroups from "@/pages/forms/formcontrols/indexGroups.vue"

import indexBootstrap from "@/pages/tables/indexBootstrap.vue"
import indexDropzone from "@/pages/advance/indexDropzone.vue"

import indexBreadcrumb from "@/pages/advance/indexBreadcrumb.vue"

import indexApexchart from "@/pages/charts/indexApexchart.vue"


import loginSimple from "@/pages/authentication/loginSimple.vue"
import loginImage from "@/pages/authentication/loginImage.vue"
import loginImagetwo from "@/pages/authentication/loginImagetwo.vue"
import loginValidation from "@/pages/authentication/loginValidation.vue"
import loginTooltip from "@/pages/authentication/loginTooltip.vue"
import loginSweetalert from "@/pages/authentication/loginSweetalert.vue"
import registerSimple from "@/pages/authentication/registerSimple.vue"
import registerImage from "@/pages/authentication/registerImage.vue"
import registerVideo from "@/pages/authentication/registerVideo.vue"
import unlockUser from "@/pages/authentication/unlockUser.vue"
import forgetPassword from "@/pages/authentication/forgetPassword.vue"
import resetPassword from "@/pages/authentication/resetPassword.vue"
import maintenanceView from "@/pages/authentication/maintenanceView.vue"

import indexInbox from "@/pages/emaill/indexInbox.vue"
import indexRead from "@/pages/emaill/indexRead.vue"
import indexCompose from "@/pages/emaill/indexCompose.vue"


import campaign from "@/pages/campaignpage/campaign.vue"
import indexSupport from "@/pages/support/indexSupport.vue"
import gettingStarted from "@/pages/support/gettingStarted.vue"
import dashboardSupport from "@/pages/support/dashboardSupport.vue"
import integrationsSupport from "@/pages/support/integrationsSupport.vue"
import faqsSupport from "@/pages/support/faqsSupport.vue"
import productsSupport from "@/pages/support/productsSupport.vue"
import apiSupport from "@/pages/support/apiSupport.vue"
import resourcesSupport from "@/pages/support/resourcesSupport.vue"

import indexIntegrate from "@/pages/integration/indexIntegrate.vue"

import workspace from "@/pages/workspace/workspace.vue"

import facebookintegrationSettings from "@/pages/integration/facebookintegrationSettings.vue"
import klaviyointegrationSettings from "@/pages/integration/klaviyointegrationSettings.vue"
import moengageintegrationSettings from "@/pages/integration/moengageintegrationSettings.vue"
import mailchimpintegrationSettings from "@/pages/integration/mailchimpintegrationSettings.vue"


import team from "@/pages/team/team.vue"
import optout from "@/pages/optform/optout.vue"
import privacyPolicy from "@/pages/privacy page/privacyPolicy.vue"
import termsAndConditions from "@/pages/privacy page/T&C.vue"


import scriptInstallation from "@/pages/setupGuide/scriptInstallation.vue"
import reVisitor from "@/pages/reVisitor/index.vue"
import visitor from "@/pages/visitor/index.vue"
//import developerSetting from "@/pages/developerSetting/developerList.vue"
import developerList from "@/pages/developersetting/developerList.vue"
import overView from "@/pages/overView/indexDefault.vue"
import { authGuard } from '@/middleware/authMiddleware';






const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/auth/login'
  },
  {
    path: "/auth",
    component: auth,
    children: [
      {
        path: "login",
        name: "login",
        component: login
      }
    ]
  },


 {
   path: "/dashboards",
   component: BodyView,
   children: [
     {
       path: "/dashboards",
       name: "default",
       component: indexDefault
     },
    // {
  //     // path: "/campaign",
  //     // name: "campaignpage",
  //     // component: campaign
  //     // },
  //     // {
  //     //   path: "e-commerce",
  //     //   name: "e-commerce",
  //     //   component: indexEcommerce
  //     // },


    ]
   },


  {
    path: "/dashboards",
    component: BodyView,
    children: [

      {
        path: "/workspace",
        name: "workspace",
        component: workspace
      },

    ]
  },




  //   {
  //     path: "/dashboards",
  //     component: BodyView,
  //     children: [

  //   {
  //     path: "/team",
  //     name: "team",
  //     component: team
  //   }
  // ]
  // },



  //Pages

  {
    path: "/",
    component: BodyView,
    children: [
      {
        path: "/setup-guide",
        name: "scriptInstallation",
        component: scriptInstallation,
        beforeEnter: authGuard,
      },

      {
        path: "/identification",
        name: "reVisitor",
        component: reVisitor,
        beforeEnter: authGuard,
      },
      {
        path: "/visitor",
        name: "visitor",
        component: visitor,
        beforeEnter: authGuard,
      },
      {
        path: "/developer-settings",
        name: "developerList",
        component: developerList,
        beforeEnter: authGuard,
      },
      
    ]
  },

  {
    path: '/overview',
    component: BodyView,
    children: [
      {
        path: '',
        name: 'overView',
        component: overView,
        beforeEnter: authGuard,
      },
    ]
  },

  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: privacyPolicy
  },

  {
    path: "/dashboards",
    component: BodyView,
    children: [

      {
        path: "/optout",
        name: "optout",
        component: optout
      }
    ]
  },

  {
    path: "/support",
    component: BodyView,
    children: [
      //     {
      //       path: "/support",
      //       component: indexSupport
      //     },
      //     {
      //       path: "/gettingStarted",
      //       component: gettingStarted,
      //       name : "gettingStarted"
      //     },
      //     {
      //       path: "/apiSupport",
      //       component: apiSupport,
      //       name : "apiSupport"
      //     },
      //     {
      //       path: "/dashboardSupport
      //       component: dashboardSupport,
      //       name : "dashboardSupport"
      //     },
      {
        path: "/faqs",
        component: faqsSupport,
        name: "faqs"
      },
      {
        path: "/intergrationsSupport",
        component: integrationsSupport,
        name: "intergrationsSupport"
      },
      //     {
      //       path: "/productsSupport",
      //       component: productsSupport,
      //       name : "productsSupport"
      //     },
      //     {
      //       path: "/resourcesSupport",
      //       component: resourcesSupport,
      //       name : "resourcesSupport"
      //     },
    ]
  },

  // {
  //   path: "/integration",
  //   component: BodyView,
  //   children : [
  //     {
  //       path: "/integration",
  //       name: "Integration",
  //       component: indexIntegrate
  //     },
  //     {
  //       path: "/facebookintegrationSettings",
  //       name: "FacebookIntegrationSettings",
  //       component: facebookintegrationSettings
  //     },
  //     {
  //       path: "/klaviyointegrationSettings",
  //       name: "KlaviyoIntegrationSettings",
  //       component: klaviyointegrationSettings
  //     },
  //     {
  //       path: "/moengageintegrationSettings",
  //       name: "MoengageIntegrationSettings",
  //       component: moengageintegrationSettings
  //     },
  //     {
  //       path: "/mailchimpintegrationSettings",
  //       name: "MailchimpIntegrationSettings",
  //       component: mailchimpintegrationSettings
  //     }
  //   ]
  // },

  {
    path: "/app",
    component: BodyView,
    children: [
      {
        path: "chat",
        name: "Chatapp",
        component: indexChat
      },
      {
        path: "video-chat",
        name: "videochat",
        component: indexVideo
      }
    ]
  },

  // {
  //   path: "/developer-setting",
  //   component: BodyView,
  //   children: [
  //     {
  //       path: "developerList",
  //       name: "developerList",
  //       component: developerList
  //     },
  //     // {
  //     //   path: "video-chat",
  //     //   name: "videochat",
  //     //   component: indexVideo
  //     // }
  //   ]
  // },


  {
    path: "/ecommerce",
    component: BodyView,
    children: [
      {
        path: "product",
        name: "product",
        component: indexProduct
      },

      {
        path: "details/:id",
        name: "productPage",
        component: productPage
      },

      // {
      //   path: "payment/details",
      //   name: "paymentDetail",
      //   component: paymentDetail
      // },
      {
        path: "invoice",
        name: "Invoice",
        component: indexInvoice
      },
      // {
      //   path: "/payments",
      //   name: "Checkout",
      //   component: indexCheckout
      // },
      {
        path: "pricing",
        name: "pricing",
        component: indexPricing
      },
      {
        path: "wishlist",
        name: "Wishlist",
        component: indexWishlist
      },
      {
        path: "order/history",
        name: "Orderhistory",
        component: indexOrder
      },
      {
        path: "productlist",
        name: "productlist",
        component: indexProductlist
      }
    ]
  },
  {
    path: "/users",
    component: BodyView,
    children: [
      {
        path: "profile",
        name: "userProfile",
        component: indexProfile
      },
      // {
      //   path: "/accountsetting",
      //   name: "indexEdit",
      //   component: indexEdit
      // },

    ]
  },



  {
    path: "/form",
    component: BodyView,
    children: [

      {
        path: "inputs",
        name: "indexInputs",
        component: indexInputs
      },

      {
        path: "input-groups",
        name: "indexGroups",
        component: indexGroups
      },


    ]
  },


  // {
  //   path: "/table",
  //   component: BodyView,
  //   children: [
  //     {
  //       path: "/leads",
  //       name: "bootstrp",
  //       component: indexBootstrap
  //     }

  //   ]
  // },
  // {
  //   path: "/agencyDashboard",
  //   component: BodyView,
  //   children: [
  //     {
  //       path: "/agencyDashboard",
  //       name: "agencyView",
  //       component: agencyView  
  //     }
  //   ]  
  // },

  // {
  //   path: "/emailTemp",
  //   component: BodyView,
  //   children: [
  //     // {
  //     //   path: "emailsupport",
  //     //   name: "emailsupport",
  //     //   component: emailsupport  
  //     // },
  //     {
  //       path: "emailIndex",
  //       name: "emailIndex",
  //       component: emailIndex  
  //     }
  //   ]  
  // },


  {
    path: "/advance",
    component: BodyView,
    children: [

      {
        path: "dropzone",
        name: "Dropzone",
        component: indexDropzone
      },



      {
        path: "breadcrumb",
        name: "Breadcrumb",
        component: indexBreadcrumb
      },


    ]
  },

  {
    path: "/chart",
    component: BodyView,
    children: [
      {
        path: "apexChart",
        name: "Apexchart",
        component: indexApexchart
      },

    ]
  },


  {
    path: "/authentication/simple",
    name: "loginsimple",
    component: loginSimple
  },
  {
    path: "/authentication/login/one",
    name: "loginimage",
    component: loginImage
  },
  {
    path: "/authentication/login/two",
    name: "loginImagetwo",
    component: loginImagetwo
  },
  {
    path: "/authentication/login/validate",
    name: "loginValidation",
    component: loginValidation
  },
  {
    path: "/authentication/login/tooltip",
    name: "loginTooltip",
    component: loginTooltip
  },
  {
    path: "/authentication/login/sweetalert",
    name: "loginSweetalert",
    component: loginSweetalert
  },
  {
    path: "/authentication/register/video",
    name: "registerVideo",
    component: registerVideo
  },
  // {
  //   path: "/auth/register",
  //   name: "registerSimple",
  //   component: registerSimple
  // },
  {
    path: "/authentication/register/image",
    name: "registerImage",
    component: registerImage
  },
  {
    path: "/authentication/unlockuser",
    name: "unlockUser",
    component: unlockUser
  },
  {
    path: "/authentication/forget-password",
    name: "forgetPassword",
    component: forgetPassword
  },
  {
    path: "/authentication/reset-password",
    name: "resetPassword",
    component: resetPassword
  },
  {
    path: "/authentication/maintenance",
    name: "maintenanceView",
    component: maintenanceView
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: privacyPolicy
  },
  {
    path: "/termsAndConditions",
    name: "termsAndConditions",
    component: termsAndConditions
  },
  {
    path: "/email",
    component: BodyView,
    children: [
      {
        path: "email-inbox",
        name: "emailInbox",
        component: indexInbox
      },
      {
        path: "read",
        name: "emailRead",
        component: indexRead
      },
      // {
      //   path: "/email-compose",
      //   name: "emailCompose",
      //   component: indexCompose
      // }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: 'active',
});
// router.beforeEach((to, from, next) => {
//   const path = ['/auth/login', '/auth/register'];
//   if (path.includes(to.path) || localStorage.getItem('user')) {
//     return next();
//   }
//   next('/auth/login');
// });

export default router
