<template>
  <div class="container-fluid">
    <div class="login-card">
      <div class="login-main">
        <form class="theme-form" @submit.prevent="onSubmit">
          <h4 class="text-center">Sign in to account</h4>
          <p class="text-center" style="margin-bottom: 15px;">Enter your email & password to login</p>
          <div v-if="serverError" class="toaster-error">{{ serverError }}</div>
          <div class="form-group">
            <label class="col-form-label">Email Address</label>
            <input
              class="form-control"
              type="email"
              :class="formSubmitted ? (emailError ? 'is-invalid' : 'is-valid') : ''"
              v-model="email"
              placeholder="Test@gmail.com"
            />
            <div class="invalid-feedback" v-if="formSubmitted && emailError">
              {{ emailErrorMessage }}
            </div>
          </div>
          <div class="form-group">
            <label class="col-form-label">Password</label>
            <input
              class="form-control"
              type="password"
              :class="formSubmitted ? (passwordError ? 'is-invalid' : 'is-valid') : ''"
              v-model="password"
              placeholder="*********"
            />
            <div class="invalid-feedback" v-if="formSubmitted && passwordError">
              {{ passwordErrorMessage }}
            </div>
          </div>
          <div class="form-group mb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div class="checkbox p-0">
                <input id="checkbox1" type="checkbox" v-model="rememberMe" />
                <label class="text-muted" for="checkbox1">Remember password</label>
              </div>
              <router-link to="/authentication/forget-password">Forgot password?</router-link>
            </div>
            <div class="text-end mt-3">
              <button class="btn btn-primary btn-block w-100" type="submit">Sign in</button>
            </div>
          </div>
          <p class="mt-4 mb-0 text-center">
            <!-- Don't have an account?<router-link class="ms-2" to="/auth/register">Create Account</router-link> -->
          </p>
        </form>
      </div>
    </div>
    <div class="text-center mt-3">
      <router-link class="ms-2" to="/privacyPolicy">Privacy Policy</router-link>
      <span class="mx-2">|</span>
      <router-link class="ms-2" to="/termsAndConditions">Terms and Conditions</router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { createToaster } from '@meforma/vue-toaster';
import { login } from '@/api/auth';

const email = ref('');
const password = ref('');
const rememberMe = ref(false);
const formSubmitted = ref(false);
const emailError = ref(false);
const passwordError = ref(false);
const emailErrorMessage = ref('');
const passwordErrorMessage = ref('');
const serverError = ref('');

const toaster = createToaster({});
const router = useRouter();

function validateInputs() {
  formSubmitted.value = true;
  emailError.value = false;
  passwordError.value = false;
  emailErrorMessage.value = '';
  passwordErrorMessage.value = '';

  if (!email.value || !validateEmail(email.value)) {
    emailError.value = true;
    emailErrorMessage.value = 'Please enter a valid email address.';
  }
  if (!validatePassword(password.value)) {
    passwordError.value = true;
    passwordErrorMessage.value = 'Password must be at least 6 characters long.';
  }
}

function validateEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function validatePassword(password: string): boolean {
  return password.length >= 6;
}

async function onSubmit() {
  validateInputs();
  if (!emailError.value && !passwordError.value) {
    try {
      const response = await login(email.value, password.value, rememberMe.value);
      localStorage.setItem('token', response.token);
      router.push('/overview');
    } catch (error: any) {
      if (error.response && error.response.status >= 400 && error.response.status < 500) {
        serverError.value = error.response.data.message || 'Something went wrong. Please try again later.';
      } else {
        serverError.value = 'Something went wrong. Please try again later.';
      }
    }
  }
}

// function success() {
//   toaster.show('Verification link has been sent to your email', {
//     theme: 'outline',
//     position: 'top-right',
//     type: 'success',
//     duration: 5000,
//   });
// }
</script>

<style scoped>
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ms-2 {
  margin-left: 0.5rem;
}
.mt-3 {
  margin-top: 10px;
}
.invalid-feedback {
  display: block;
  color: red;
}
.toaster-error {
  color: red;
  text-align: center;
  margin-bottom: 15px;
}
</style>
