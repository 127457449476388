
import { request } from '../utils/axios';

const baseURL = process.env.VUE_APP_BASE_URL;

if (!baseURL) {
  throw new Error('Base URL is not defined in the environment variables');
}

export interface ForgotPasswordPayload {
  email: string;
}

export interface ChangePasswordPayload {
  newPassword: string;
}

const handleError = (error: unknown): string => {
  if (error && typeof error === 'object' && 'response' in error) {
    return (error as any).response?.data?.message || 'An error occurred';
  }
  return 'An unexpected error occurred';
};

export const forgotPassword = async (payload: ForgotPasswordPayload): Promise<void> => {
  try {
    await request({
      method: 'post',
      url: `${baseURL}/auth/forgot-password`,
      data: payload,
      headers: { 'Content-Type': 'application/json' }
    });
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const changePassword = async (payload: ChangePasswordPayload, token: string): Promise<void> => {
  try {
    await request({
      method: 'post',
      url: `${baseURL}/auth/change-password`,
      data: payload,
      headers: { 'Content-Type': 'application/json', 'authorization': token }
    });
  } catch (error) {
    throw new Error(handleError(error));
  }
};
